<template>
    <b-card no-body class="h-100 mb-2">
      <b-card-header>
        <b-card-title>Social Network Analytic</b-card-title>
      </b-card-header>
      <b-card-body>
        <!-- NETWORK GRAPH -->
        <networkGraph />
  
        <!-- LEGENDS -->
        <div class="float-right d-flex mt-3">
          <div class="mr-1">Warna Node :</div>
          <span class="mr-1">
            <i class="bi bi-circle-fill" style="color: #28c76f"> </i> Positif
          </span>
          <span class="mr-1">
            <i class="bi bi-circle-fill" style="color: #7367f0"> </i> Netral
          </span>
          <span class="mr-1">
            <i class="bi bi-circle-fill" style="color: #ea5455"> </i> Negatif
          </span>
          <span class="mr-1">
            <i class="bi bi-circle-fill" style="color: #ff9f43"> </i> Retweeter
          </span>
        </div>
      </b-card-body>
    </b-card>
  </template>
  <script>
  import { BCard, BCardHeader, BCardTitle, BCardBody } from "bootstrap-vue";
  import { $themeColors } from "@themeConfig";
  import networkGraph from "@/components/usable/networkGraph.vue";
  
  export default {
    name: "SNA",
    components: { BCard, BCardHeader, BCardTitle, BCardBody, networkGraph },
  
    data: () => ({
      title: "Social Network Analytic | Online Media Monitoring",
    }),
  
    created() {
      document.title = this.title;
    },
  };
  </script>
  <style src="vue-d3-network/dist/vue-d3-network.css"></style>
  